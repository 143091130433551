import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import PrivateRoute from "../components/privateRoute"
import Dashboard from "./dashboard"
import Login from "./login"
import NavBar from '../components/userNav'

const App = () => (
  <Layout>
    <NavBar/>
    <Router>
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <Login path="/login" />
    </Router>
  </Layout>
)

export default App